const tabNavs = document.querySelectorAll("[data-tab]");
const tabIds = document.querySelectorAll("[data-tab-id]");

tabNavs.forEach((tabNav) => {
    const tabLinks = tabNav.querySelectorAll('a');

    tabLinks.forEach((tabLink) => {
        tabLink.addEventListener("click", (e) => {

            if(!e.target.classList.contains("tab-url")) {
                e.preventDefault();
            }

            const hash = e.target.hash.substring(1);
            const active = e.target.closest("li");

            tabLinks.forEach((e) => {
                e.closest('li').classList.remove("active");
            });

            tabIds.forEach((tabId) => {
                const tabContents = tabId.querySelectorAll("[data-tab-content]");

                if (tabNav.dataset.tab === tabId.dataset.tabId) {
                    tabContents.forEach((e) => {
                        const tabContentId = e.dataset.tabContent;

                        e.classList.remove('active')
                        if (hash === tabContentId) {
                            e.classList.add('active')
                        }
                    });
                    active.classList.add("active");
                }
            });

        });
    });
});