import videojs from 'video.js';
import PDFjs from 'pdfjs-dist'

import {accordion} from "./components/";
import {gallerySlider} from "./components/";
import {mobileHack} from "./components/";
import {navigation} from "./components/";
import {navigationMobile} from "./components/";
import {heroSlider} from "./components/";
import {quoteSlider} from "./components/";
import {searchbar} from "./components/";
import {sidebar} from "./components/";
import {sidebarAnsprechpartner} from "./components/";
import {standorteSlider} from "./components/";
import {startseiteAnimation} from "./components/";
import {tab} from "./components/";

gallerySlider();
heroSlider();
quoteSlider();
standorteSlider();