import Swiper from 'swiper';
import { Pagination, Keyboard, EffectFade } from 'swiper/modules';

const heroSlider = () => {
    window.addEventListener("load", function () {
        const swiperHero = new Swiper('.hero-slider', {
            modules: [Pagination, Keyboard, EffectFade],
            spaceBetween: 0,
            slidesPerView: 1,
            threshold: 10,
            effect: "fade",
            crossFade: true,
            loop: true,

            keyboard: {
                enabled: true,
            },

            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
        });
    });
};

export default heroSlider;
