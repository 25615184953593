// Input If Filled
const formInputs = document.querySelectorAll("input");
formInputs.forEach((e) => {
    e.addEventListener('input', () => {
        e.value !== "" ? e.classList.add('filled') : e.classList.remove('filled')
    });
});

// Textarea If Filled
const formTextarea = document.querySelectorAll("textarea");
formTextarea.forEach((e) => {
    e.addEventListener('input', () => {
        e.value !== "" ? e.classList.add('filled') : e.classList.remove('filled')
    });
});



// Select
// Look for any elements with the class "custom-select":
const formSelecter = document.querySelectorAll(".form-select-custom");

formSelecter.forEach(formSelecterItem => {
    const formSelect = formSelecterItem.querySelector("select");

    // Select if filled
    formSelect.addEventListener('change', (e) => {
        e.target.selectedIndex >= 1 ? e.target.classList.add('filled') : e.target.classList.remove('filled')
    })

    // formSelect.addEventListener("change", function () {
    //     const form = this.closest('form');
    //     const queryInput = form.querySelector('input[name="q"]');

    //     if (queryInput) {
    //         queryInput.value = document.getElementById('queryinput').value;
    //     }

    //     form.submit();
    // });

    // For each element, create a new DIV that will act as the selected item:
    const selectedItem = document.createElement("DIV");
    selectedItem.setAttribute("class", "select-selected");
    selectedItem.innerHTML = formSelect.options[formSelect.selectedIndex].innerHTML;
    formSelecterItem.appendChild(selectedItem);

    // For each element, create a new DIV that will contain the option list:
    const optionList = document.createElement("DIV");
    optionList.setAttribute("class", "select-items select-hide");

    // For each option in the original select element, create a new DIV that will act as an option item:
    formSelect.querySelectorAll('option').forEach(option => {
        const newOption = document.createElement("DIV");
        newOption.innerHTML = option.innerHTML;
        newOption.dataset.value = option.value;

        newOption.addEventListener("click", function () {
            // When an item is clicked, update the original select box and the selected item:
            const currentOptionList = this.parentNode;
            const currentFilterSelect = currentOptionList.parentNode;
            this.setAttribute("class", "selected");

            const selectedOptions = currentOptionList.querySelectorAll(".selected");
            selectedOptions.forEach(selectedOption => {
                selectedOption.removeAttribute("class");
            });

            const selectSelected = currentFilterSelect.querySelector(".select-selected");
            selectSelected.innerHTML = this.innerHTML;
            selectSelected.click();

            const originSelect = currentFilterSelect.querySelector("select");
            originSelect.value = this.dataset.value;
            originSelect.dispatchEvent(new Event('change'));
        });

        optionList.appendChild(newOption);
    });

    formSelecterItem.appendChild(optionList);

    selectedItem.addEventListener("click", function (event) {
        // When the select box is clicked, close any other select boxes, and open/close the current select box:
        if (!this.classList.contains("select-arrow-active")) {
            event.stopPropagation();
            closeAllOptionLists(this);
            this.nextSibling.classList.toggle("select-hide");
            this.classList.add("select-arrow-active");
        }
    });
});

function closeAllOptionLists(element) {
    // A function that will close all select boxes in the document, except the current select box:
    const listsToHide = [];
    const optionLists = document.querySelectorAll(".select-items");
    const selectedOptions = document.querySelectorAll(".select-selected");

    for (let i = 0; i < selectedOptions.length; i++) {
        if (element == selectedOptions[i]) {
            listsToHide.push(i)
        }
        else {
            selectedOptions[i].classList.remove("select-arrow-active");
        }
    }

    optionLists.forEach(optionList => {
        if (listsToHide.indexOf(optionList)) {
            optionList.classList.add("select-hide");
        }
    });
}

// If the user clicks anywhere outside the select box, then close all select boxes:
document.addEventListener("click", closeAllOptionLists);
