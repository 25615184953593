const navHeader = document.querySelector(".header");

function scrollNavigation() {
    if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 200) {
       navHeader.classList.add('header--scroll');
    } else {
       navHeader.classList.remove('header--scroll');
    }
 }

 window.onscroll = () => scrollNavigation();