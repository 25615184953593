const animationBoxes = document.querySelectorAll(".grid-box-start__box--video");

if (animationBoxes) {
    animationBoxes.forEach((e) => {
        e.querySelector("video").pause();
        e.querySelector("video").currentTime = 0;
    });

    animationBoxes.forEach((e) => {
        e.addEventListener('mouseover', () => {
            e.querySelector("video").currentTime = 0;
            e.querySelector("video").play();

        });

        e.addEventListener('mouseout', () => {
            e.querySelector("video").pause();
            e.querySelector("video").currentTime = 0;
        });
    });
}
