import Swiper from 'swiper';
import { Navigation, Pagination, Thumbs, Autoplay, Keyboard } from 'swiper/modules';

const gallerySlider = () => {
    window.addEventListener("load", function () {

        const gallerySlider = document.querySelectorAll('.gallery-slider');
        for (let i = 0; i < gallerySlider.length; i++) {
            gallerySlider[i].classList.add('gallery-slider--' + i);

            let galleryThumb = new Swiper('.gallery-slider--' + i + ' ' + '.gallery-slider-thumbs', {
                modules: [Navigation],
                spaceBetween: 24,
                slidesPerView: 4,
                centerInsufficientSlides: true,
                threshold: 10,
                navigation: {
                    nextEl: '.gallery-slider--' + i + ' ' + '.gallery-slider-next',
                    prevEl: '.gallery-slider--' + i + ' ' + '.gallery-slider-prev',
                },
            });

            let galleryMain = new Swiper('.gallery-slider--' + i + ' ' + '.gallery-slider-main', {
                modules: [Thumbs, Keyboard, Autoplay],
                spaceBetween: 0,
                slidesPerView: 1,
                threshold: 10,
                centeredSlides: true,
                loop: true,
                autoplay: {
                    delay: 5000,
                    pauseOnMouseEnter: true
                },
                // allowTouchMove: false,

                keyboard: {
                    enabled: true,
                    onlyInViewport: true,
                },

                thumbs: {
                    swiper: galleryThumb,
                }
            });
        }
    }, false);
};

export default gallerySlider;
