var sidebar = document.querySelector('.sidebar');

if (sidebar) {
    var footer = document.querySelector('.footer');

    const observeOptions = {
        root: null,
        threshold: 0.2
    };

    const observeAni = new IntersectionObserver(function (entries, observer) {
        entries.forEach((entry) => {
            if (!entry.isIntersecting) {
                sidebar.classList.remove('hide');
            } else {
                sidebar.classList.add('hide');
            }
        });
    }, observeOptions);

    observeAni.observe(footer);
}

