const openModal = document.querySelectorAll("[data-modal-id]");
const modal = document.querySelectorAll(".modal");
const body = document.body;

openModal.forEach((e) => {
    //open modal by id
    const modalId = document.querySelector("#" + e.dataset.modalId);
    e.addEventListener("click", () => {
        modalId.showModal();
        body.classList.add("modal-overflow");

        if(modalId.matches("[data-modal='video']") || modalId.matches("[data-modal='video-small']")) {
            modalId.querySelector("video").play();
        }
    });
});

modal.forEach((e) => {
    //close modal on button
    const closeBtn = e.querySelector("[data-modal='close']");
    closeBtn.addEventListener("click", () => {
        body.classList.remove("modal-overflow");
        closeModal(e);
    });


    document.onkeyup = function (event) {
        if (event.keyCode === 27) {
            body.classList.remove("modal-overflow");
        }
    }

    //close modal on modalBody
    const modalBody = e.querySelector(".modal-body");
    modalBody.addEventListener("click", (a) => {
        if (a.target == modalBody) {
            body.classList.remove("modal-overflow");
            closeModal(e);
        }
    });
});

const closeModal = (e) => {
    //close modal function
    e.setAttribute("closing", "");
    e.addEventListener(
        "animationend",
        () => {
            e.removeAttribute("closing");
            e.close();
        },
        { once: true }
    );

    // Modal Video Stop
    if (e.matches("[data-modal='video']") || e.matches("[data-modal='video-small']")) {
        e.querySelector("video").pause();
    }
};