let sidebarAnsprechpartner = document.querySelector('.sidebar-ansprechpartner');

if (sidebarAnsprechpartner) {
    let sidebarAnsprechpartnerClose = document.querySelector('.sidebar-ansprechpartner__close');
    let footer = document.querySelector('.footer');

    sidebarAnsprechpartnerClose.addEventListener('click', () => {
        sidebarAnsprechpartner.classList.add('close');
    });

    const observeOptions = {
        root: null,
        threshold: 0.2
    };

    const observeAni = new IntersectionObserver(function (entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                sidebarAnsprechpartner.classList.add('hide');
            } else {
                sidebarAnsprechpartner.classList.remove('hide');
            }
        });
    }, observeOptions);

    observeAni.observe(footer);
}