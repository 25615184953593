const navigationMobile = document.querySelector(".navigation-mobile");

if (navigationMobile) {
   const body = document.body;
   const main = document.querySelector("main");
   const btnMenu = document.querySelector(".header__btn-menu");
   const btnMore = document.querySelectorAll(".btn-more");
   const menuDropdowns = navigationMobile.querySelectorAll(".menu");

   //Menu Hamburger
   const menuToggleOpen = () => {
      setTimeout(() => {
         body.style.overflow = "hidden";
         btnMenu.setAttribute("aria-expanded", "true");
         navigationMobile.classList.add("show");

      }, 1);
   };

   const menuToggleClose = () => {
      setTimeout(() => {
         body.removeAttribute("style");
         btnMenu.setAttribute("aria-expanded", "false");
         navigationMobile.classList.remove("show");
      }, 1);
   };


   btnMenu.addEventListener('click', (e) => {
      if (btnMenu.getAttribute("aria-expanded") == "false") {
         menuToggleOpen();
      } else {
         menuToggleClose();
      }
   });

   main.addEventListener('click', (e) => {
      menuToggleClose();
   });

   //Dropdown
   const menuDropdownToggleOpen = (e) => {
      btnMore.forEach((e) => {
         e.setAttribute("aria-expanded", "false");
      });

      menuDropdowns.forEach((e) => {
         e.classList.remove("show");
      });

      setTimeout(() => {
         e.target.setAttribute("aria-expanded", "true");
         e.target.parentNode.nextElementSibling.classList.add("show");
      }, 1);
   };

   const menuDropdownToggleClose = (e) => {
      setTimeout(() => {
         e.target.setAttribute("aria-expanded", "false");
         e.target.parentNode.nextElementSibling.classList.remove("show");
      }, 1);
   };

   btnMore.forEach((btnMoreItem) => {
      btnMoreItem.addEventListener('click', (e) => {
         if (e.target.getAttribute("aria-expanded") == "false") {
            menuDropdownToggleOpen(e);
         } else {
            menuDropdownToggleClose(e);
         }
      });
   });

   menuDropdowns.forEach((e) => {
      if (e.parentNode.classList.contains("active")) {
         let btn = e.previousElementSibling.lastElementChild;
         // e.setAttribute("aria-hidden", "false");
         e.classList.add("show");
         btn.setAttribute("aria-expanded", "true");
      }
   });
}