import Swiper from 'swiper';
import { Navigation, Keyboard, EffectFade } from 'swiper/modules';

const standorteSlider = () => {
    window.addEventListener("load", function () {
        const swiperStandorte = new Swiper('.standorte-slider', {
            modules: [Navigation, Keyboard, EffectFade],
            spaceBetween: 0,
            slidesPerView: 1,
            threshold: 10,
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            loop: true,
            
            // keyboard: {
            //     enabled: true,
            // },

            breakpoints: {
                768: {
                    allowTouchMove: false
                },

            },

            navigation: {
                nextEl: '.standorte-next',
                prevEl: '.standorte-prev',
            },
        });

        //Active First Pin
        let standorteMapPin = document.querySelectorAll(".standorte-map__pin");

        //onSlideChange toggle class on map
        swiperStandorte.on('slideChange', function () {
            var slideNumber = swiperStandorte.realIndex;

            standorteMapPin.forEach(e => {
                e.classList.remove("standorte-map__pin--active");
            });

            standorteMapPin[slideNumber].classList.add("standorte-map__pin--active");
        });

        //onclick on map change slides
        standorteMapPin.forEach((e, index) => {
            e.addEventListener('click', () => {
                console.log(index)
                swiperStandorte.slideTo(index);
            });
        });
    });
};

export default standorteSlider;
