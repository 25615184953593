const searchbar = document.querySelector(".searchbar");

if (searchbar) {
   const btnSearch = document.querySelector(".header__btn-search");
   const main = document.querySelector("main");

    //Search Toggle
    const searchToggleOpen = () => {
      setTimeout(() => {
         btnSearch.setAttribute("aria-expanded", "true");
         searchbar.classList.add("show");
      }, 1);
   };

   const searchToggleClose = () => {
      setTimeout(() => {
         btnSearch.setAttribute("aria-expanded", "false");
         searchbar.classList.remove("show");
      }, 1);
   };

   btnSearch.addEventListener('click', (e) => {
      if (btnSearch.getAttribute("aria-expanded") == "false") {
         searchToggleOpen();
      } else {
         searchToggleClose();
      }
   });

   main.addEventListener('click', (e) => {
      searchToggleClose();
   });

}

const userListSearch = document.querySelectorAll(".user-list-search");

userListSearch.forEach(searchInput => {
   searchInput.addEventListener('keyup', (e) => {
      const searchValue = e.target.value.toLowerCase();
      const userList = searchInput.closest(".table");
      const userItem = userList.querySelectorAll("tbody tr");

      userItem.forEach((item) => {
         const data = item.textContent.toLowerCase();
         const checkbox = item.querySelector("[type='checkbox']");

         if (data.indexOf(searchValue) > -1) {
            item.style.display = "";
         } else {
            item.style.display = "none";
            checkbox.checked = false;
         }
      });
   });
});

