import Swiper from 'swiper';
import { Navigation, Pagination, Thumbs, Autoplay, Keyboard, EffectFade } from 'swiper/modules';

const quoteSlider = () => {
    window.addEventListener("load", function () {

        //Slider

        const quoteImage = new Swiper(".quote-slider-image", {
            modules: [Thumbs, Navigation, EffectFade, Pagination],
            spaceBetween: 0,
            slidesPerView: 1,
            threshold: 10,
            effect: "fade",
            crossFade: true,
            loop: true,
            allowTouchMove: false,

            keyboard: {
                enabled: true,
            },
        });

        const quoteContent = new Swiper(".quote-slider-content", {
            modules: [Thumbs, Keyboard, EffectFade, Navigation, Pagination],
            spaceBetween: 40,
            slidesPerView: 1,
            threshold: 10,
            centeredSlides: true,
            loop: true,
            allowTouchMove: false,

            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            thumbs: {
                swiper: quoteImage,
            },
            pagination: {
                el: ".quote-highlight__pagination",
                type: "fraction",
            },

            navigation: {
                nextEl: '.quote-highlight__next',
                prevEl: '.quote-highlight__prev',
            },
        });

    }, false);
};

export default quoteSlider;
