// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

function findAncestor(el, sel) {
	while ((el = el.parentElement) && !(el.matches || el.matchesSelector).call(el, sel));
	return el;
}

const accordions = document.querySelectorAll(".accordion");

accordions.forEach(function (accordion) {
	const items = accordion.querySelectorAll(".accordion__item");
	const questions = accordion.querySelectorAll(".accordion__button");

	questions.forEach(function (question) {
		question.addEventListener("click", function () {
			const thisItem = findAncestor(this, ".accordion__item");

			// Anchor
			// if (!thisItem.classList.contains('accordion__item--show')) {
			// 	setTimeout(() => {
			// 		const thisItemPosition = thisItem.getBoundingClientRect().top;
			// 		const offsetPosition = thisItemPosition + window.pageYOffset;

			// 		window.scrollTo({
			// 			top: offsetPosition - 10,
			// 			behavior: "smooth",
			// 		});
			// 	}, 300);
			// }

			items.forEach(function (item) {
				if (thisItem == item) {
					thisItem.classList.toggle("accordion__item--show");
					return;
				}

				item.classList.remove("accordion__item--show");
			});
		});
	});
});

const accordionsSub = document.querySelectorAll(".accordion-sub");

accordionsSub.forEach(function (accordionSub) {
	const items = accordionSub.querySelectorAll(".accordion-sub__item");
	const questions = accordionSub.querySelectorAll(".accordion-sub__button");

	questions.forEach(function (question) {
		question.addEventListener("click", function () {
			const thisItem = findAncestor(this, ".accordion-sub__item");

			// Anchor
			// if (!thisItem.classList.contains('accordion__item--show')) {
			// 	setTimeout(() => {
			// 		const thisItemPosition = thisItem.getBoundingClientRect().top;
			// 		const offsetPosition = thisItemPosition + window.pageYOffset;

			// 		window.scrollTo({
			// 			top: offsetPosition - 10,
			// 			behavior: "smooth",
			// 		});
			// 	}, 300);
			// }

			items.forEach(function (item) {
				if (thisItem == item) {
					thisItem.classList.toggle("accordion-sub__item--show");
					return;
				}

				item.classList.remove("accordion-sub__item--show");
			});
		});
	});
});

// Watch accordion click to complement page URL
var accordionButtons = document.querySelectorAll(".accordion__button");
accordionButtons.forEach(function (accordionBtn) {
	accordionBtn.addEventListener("click", function () {
		// Add hash title to URL
		const title = accordionBtn.dataset.title;
		if(title) {
			window.location.hash = title;
		}
	});
});

// Open accordion and jump to it
function offsetAccordionAnchor() {
	if (location.hash.length !== 0) {
		// Get hash
		var hashId = window.location.hash.split('#')[1];
		if (hashId !== "") {
			// Search for accordion element with this hash as data-title
			var accordionTabs = document.querySelectorAll(`[data-title="${hashId}"]`);

			// Open the Akkordeon Tab
			accordionTabs.forEach(function(accordionTab) {
				accordionTab.click()
				const y = accordionTab.getBoundingClientRect().top + window.scrollY - 80;
				window.scrollTo({top: y, behavior: 'smooth'});
			})
		}
	}
}

// Set the offset when entering page with hash present in the url
window.setTimeout(offsetAccordionAnchor, 0);